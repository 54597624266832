import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Accordion } from 'react-bootstrap';
import '../../css/change-record.css';
import Constants from '../Constants';
import { useAdmin} from '../../contexts/AdminContext';
import { TooltipOnHover } from '../common/Tooltip';
import {ExitModal} from '../common/ExitModal';
import { CommentSection } from './CommentSection';
import ValidationResult from './validation-result';
import {DeviceListEditor} from './devices-in-maint-editor';
import { RevalidateChange } from './revalidate-change';  
import {Helmet} from "react-helmet-async";
import { handleHttpError, handleApiError, getMaxDate } from '../common/formHandlers';

export const ViewChangeRecord = ({ show, item ,bearer, user, onhide, onRowUpdate}) => {
    // Change record being viewed
    const [change, setChange] = useState(item);
    // True when an edit has been made
    const { isAdmin } = useAdmin();
    const [closeDisabled, setCloseDisabled] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(change);
    const [showExitModal, setShowExitModal] = useState(false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [submitResponse, setSubmitResponse] = useState(null);
    const [error, setError] = useState("");
    const [eligibleStatus, setEligibleStatus] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [validateButtonLoading, setValidateButtonLoading] = useState(false);
    const [validationResult, setValidationResult] = useState('');
    const [isMopRequired, setIsMopRequired] = useState(false);
    const [maintenanceTypeList, setMaintenanceTypeList] = useState([]);

    const showHistory = () => {
        window.open(`/#/changeHistory/${item.changeId}`, '_blank');
    };

    const handleClose = () => {
        if (closeDisabled) {
            setShowExitModal(true);
        }
        else
        {
            onhide();
        }
    };

    const handleSubmitClose = () => {
        setShowSubmitModal(false);
    };
    
    useEffect(() => {
        setDataUpdate(change);
    }, [change]);

    useEffect(() => {
        getEligibleStatus(change.status)
        setValidationResult(change.validationResult);
        if (Constants.NoMopReqMaintenanceTypes.includes(change.maintenanceType)) {
            setMaintenanceTypeList(Constants.NoMopReqMaintenanceTypes);
        }
        else {
            setIsMopRequired(true);
            setMaintenanceTypeList(Constants.MopReqMaintenanceTypes);
        }
    },[change]);

    function getEligibleStatus(currentStatus) {

        //Eligible status
        //"when currentStatus = Rejected", - approved(only admin), InReview(only admin), cancelled
        //"when currentStatus = Cancelled", - nothing
        //"when currentStatus = Approved", - cancelled
        //"when currentStatus = InReview" - rejected, cancelled

        if (currentStatus === Constants.CancelledStatus) {
            return eligibleStatus
        }

        if (currentStatus === Constants.ApprovedStatus) {
            setEligibleStatus([Constants.CancelledStatus]);
            return eligibleStatus
        }

        if (currentStatus === Constants.RejectedStatus) {
            if (isAdmin) {
                setEligibleStatus([Constants.InReviewStatus, Constants.ApprovedStatus, Constants.CancelledStatus]);
                return eligibleStatus
            }
            setEligibleStatus([Constants.CancelledStatus]);
            return eligibleStatus
        }

        if (currentStatus === Constants.InReviewStatus) {
            if (isAdmin) {
                setEligibleStatus([Constants.ApprovedStatus, Constants.RejectedStatus, Constants.CancelledStatus]);
                return eligibleStatus
            }
            setEligibleStatus([Constants.RejectedStatus, Constants.CancelledStatus]);
            return eligibleStatus
        }

        return eligibleStatus
    }

    /**
     * Turns on change flag and records changes being made 
     */
    const handleInputChange = (event) => {
        setCloseDisabled(true);
        const { name, value } = event.target;
        setDataUpdate((prevData) => ({
            ...prevData,
            [name]: value,
            lastUpdatedUser: user
        }));
    };

    async function revalidateChange(change) {
        setError("");
        setValidateButtonLoading(true);
        change.lastUpdatedUser = user;
    
        try {
            validateChange(change);
    
            const response = await submitChangeUpdate(change, bearer);
    
            if (!response.ok) {
                handleHttpError(response.status);
                return;
            }
    
            const resultJson = await response.json();
    
            if (resultJson[Constants.responseCode] !== Constants.SuccessAPIResponseCode || isKustoError(resultJson)) {
                handleApiError(resultJson);
                return;
            }
    
            setSubmitResponse(resultJson);
            setChange(resultJson.data);
            onRowUpdate(resultJson.data);
            setCloseDisabled(false);
        } catch (error) {
            setSubmitResponse(null);
            setError(error.message || "There was an error validating your change. Please try again.");
        } finally {
            setValidateButtonLoading(false);
            setShowSubmitModal(true);
        }
    }
    
    async function handleSubmit(event) {
        event.preventDefault();
    
        if (!closeDisabled) {
            return;
        }
    
        setButtonLoading(true);
    
        try {
            validateChange(dataUpdate);
    
            const response = await submitChangeUpdate(dataUpdate, bearer);
    
            if (!response.ok) {
                handleHttpError(response.status);
                return;
            }
    
            const resultJson = await response.json();
    
            if (resultJson[Constants.responseCode] !== Constants.SuccessAPIResponseCode) {
                handleApiError(resultJson);
                return;
            }
    
            setSubmitResponse(resultJson);
            setChange(resultJson.data);
            onRowUpdate(resultJson.data);
        } catch (error) {
            setSubmitResponse(null);
            setError(error.message || "There was an error validating your change. Please try again.");
        } finally {
            setButtonLoading(false);
            setShowSubmitModal(true);
            setCloseDisabled(false);
        }
    }
    
    function validateChange(change) {
        if (change.scheduledStartTime > change.scheduledEndTime) {
            throw new Error("End time must be greater than start time");
        }
        if (!change.description) {
            throw new Error("Description is required");
        }
    }
    
    async function submitChangeUpdate(change, bearer) {
        const options = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": bearer,
            },
            body: JSON.stringify(change)
        };
        const url = process.env.REACT_APP_API_CLIENT_URL + 'ChangeManagement/UpdateChange';
        return await fetch(url, options);
    }
    
    function isKustoError(resultJson) {
        return resultJson.data.validationResult?.rejectedChecks.length === 1 &&
            resultJson.data.validationResult?.rejectedChecks[0][Constants.responseCode] === Constants.KustoErrorAPIResponseCode;
    }

    return (
        <div>
            <Helmet>
                <title>Change Record #{item.changeId}</title>
            </Helmet>
            <Modal className="cr-modal" size="lg" 
                show={show}
                onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Change Record #{item.changeId} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col className='col-4'>
                                <Form.Group className="mb-3">
                                    <Form.Label>Maintenance Type</Form.Label>
                                    <Form.Select disabled={[Constants.ApprovedStatus, Constants.CancelledStatus].includes(change.status)}
                                    name="maintenanceType" type="text" value={change.maintenanceType} onChange={handleInputChange} >
                                        <option></option>
                                        {maintenanceTypeList.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col className='col-4'>
                                <Form.Group className="mb-3">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select name="status" type="text" defaultValue={change.status} onChange={handleInputChange}>
                                        <option value={change.status}>{change.status}</option>
                                        {eligibleStatus.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col className="col-4 mb-3 mt-4 pt-2 text-end">
                                <RevalidateChange
                                    change={change}
                                    revalidateChange={revalidateChange}
                                    buttonLoading={validateButtonLoading}
                                    closeDisabled={closeDisabled}
                                />
                                <Button
                                    className="InputButton"
                                    type="submit"
                                    name="update-btn"
                                    disabled={buttonLoading || !closeDisabled}>
                                    {buttonLoading ? "Loading..." : "Update"}
                                </Button>
                            </Col>
                        </Row>
                        {change?.mopName &&
                        <>
                        <Row>
                            <Col className = "col-9">
                                <Form.Group className="mb-3">
                                    <Form.Label>Mop Name</Form.Label>
                                    <Form.Control name="mopName" disabled defaultValue={change.mopName}  />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Is MOP validated in NCVS</Form.Label>
                                    <Form.Control name="isNcvsValidated" disabled defaultValue={change.isNcvsValidated} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Row>
                                    <Form.Group className="mb-3">
                                        <a href={"https://aka.ms/NcosChangeManagement?workflowId=" + change.mopId} target="_blank" rel="noopener noreferrer">Mop Link</a>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3">
                                        <a href={change.ncvsDashboardUrl} target="_blank" rel="noopener noreferrer">NCVS Validation Result</a>
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Row>
                        </>}
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control disabled={[Constants.ApprovedStatus, Constants.CancelledStatus].includes(change.status)} 
                                    name="title" defaultValue={change.title} onChange={handleInputChange} />
                                </Form.Group>
                            </Col>
                            <Col className="col-3">
                                <Form.Group className="mb-3 mt-4 pt-2 text-end">
                                    <Button
                                    onClick={showHistory}
                                    > History</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control disabled={[Constants.ApprovedStatus, Constants.CancelledStatus].includes(change.status)} name="description" 
                                    as="textarea" className="cr-textarea" defaultValue={ change.description} onChange={handleInputChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        {Constants.LossOfRedundancyMaintenanceTypes.includes(item.maintenanceType) && (
                            <Form.Group className="mb-3">
                                <Form.Label>TSB-2020-07-007 Microsoft EE Approver <TooltipOnHover placement={'right'} content={Constants.UnsafeDeviceSmfVersionApproverTooltipContent} /></Form.Label>
                                <Form.Control disabled={[Constants.ApprovedStatus, Constants.CancelledStatus].includes(change.status)} name="unsafeDeviceSmfVersionApprover"
                                    type="text" defaultValue={change.unsafeDeviceSmfVersionApprover} onChange={handleInputChange} />
                            </Form.Group>
                        )}
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Risk</Form.Label>
                                    <Form.Control name="risk" disabled defaultValue={change.riskLevel}/>
                                </Form.Group>
                            </Col>
                            {change?.safeflyId &&
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Safefly ID</Form.Label>
                                    <Form.Control name="safeflyId" disabled defaultValue={change.safeflyId}/>
                                </Form.Group>
                            </Col>
                            }
                        </Row>
                        <Row>
                            <Form.Group className="mb-3 col-6" controlId="startTime">
                                <Form.Label>Start Time (UTC) <span className="text-danger">*</span></Form.Label>
                                <Form.Control required disabled={[Constants.ApprovedStatus, Constants.CancelledStatus].includes(change.status)}  type="datetime-local" 
                                name="scheduledStartTime" defaultValue={change.scheduledStartTime} onChange={handleInputChange} max={getMaxDate()} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-6" controlId="endTime">
                                <Form.Label>End Time (UTC) <span className="text-danger">*</span></Form.Label>
                                <Form.Control required disabled={[Constants.ApprovedStatus, Constants.CancelledStatus].includes(change.status)}  type="datetime-local"
                                name="scheduledEndTime" defaultValue={change.scheduledEndTime} onChange={handleInputChange} />
                            </Form.Group>
                        </Row>
                        <DeviceListEditor
                        isMopRequired={isMopRequired}
                        item={change}
                        handleInputChange={handleInputChange}
                        />
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Latest CVB Result (Refer to FAQ section to resolve the rejection)</Form.Label>
                                    {item && validationResult && (
                                        <ValidationResult validationResult={validationResult} setValidationResult={setValidationResult} changeId={change.changeId} />)
                                    }
                                </Form.Group>
                            </Col>
                            <Col className="col-3">
                                <Form.Group className="mb-3 mt-4 pt-2">
                                    <Button onClick={() => window.open("https://msazure.visualstudio.com/AzureWiki/_wiki/wikis/AzureWiki.wiki/52466/Physical-Network-CAB-process?anchor=faq", "_blank")}> FAQ</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3">
                            <Form.Label>FTE owner</Form.Label>
                            <Form.Control name="owner" disabled defaultValue={change.owner} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Notes </Form.Label>
                            <CommentSection
                            crId={item.changeId}
                            bearer={bearer}
                            user={user}
                            />
                        </Form.Group>  
                    </Form>
                </Modal.Body>
            </Modal>
            < ExitModal showExitModal={showExitModal} setShowExitModal={setShowExitModal} setCloseDisabled={setCloseDisabled} onhide={onhide} />
            <Modal show={showSubmitModal} onHide={handleSubmitClose} name="submitModal">
                <Modal.Header closeButton name="submitModal-header">
                    <Modal.Title name="submitModal-title">
                        {submitResponse ? submitResponse.ResponseMessage : 'Error'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body name = "submitModal-body">
                    {submitResponse ? 'Your update was successful!' : error || "There was an unexpected error. Please try again later."}
                </Modal.Body>
                <Modal.Footer name= "submitModal-footer">
                    <Button name = "submitModal-closebtn"onClick={handleSubmitClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}
